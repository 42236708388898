import Text from "./Text";

export function truncateText(str, maxLength, ending = "...") {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - ending.length) + ending;
    }
    return str;
}

export default function BlogPosts({ posts = [], label = "", elementId = "" }) {
    return (
        <aside aria-label={label} className="" id={elementId}>
            <div className="py-8 mx-auto text-center w-full md:max-w-screen-2xl lg:max-w-screen-2xl px-6 md:px-0 lg:px-0">
                <h2 className="text-primary font-heading text-4xl font-black w-auto">
                    {label}
                </h2>
                <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 mt-8">
                    {posts?.map((post, index) => (
                        <article key={`post_${index}`}>
                            <a
                                href={route("page.blog_post", {
                                    postId: post.slug,
                                })}
                                aria-label={`Read blog post ${post.title}`}
                                title={`Read blog post ${post.title}`}
                            >
                                <img
                                    src={post.image.url}
                                    className="w-full object-cover h-56 rounded"
                                    alt={post.title}
                                />
                            </a>
                            <h3 className="mt-6 text-primary font-heading text-2xl font-black w-auto">
                                <a
                                    href={route("page.blog_post", {
                                        postId: post.slug,
                                    })}
                                    aria-label={`Read blog post ${post.title}`}
                                    title={`Read blog post ${post.title}`}
                                >
                                    {post.title}
                                </a>
                            </h3>
                            <Text className="my-4">
                                {truncateText(post.description, 100)}
                            </Text>
                            <a
                                href={route("page.blog_post", {
                                    postId: post.slug,
                                })}
                                className="inline-flex items-center justify-center text-base font-medium text-center text-primary"
                                aria-label={`Read blog post ${post.title}`}
                                title={`Read blog post ${post.title}`}
                            >
                                Read in 2 minutes
                            </a>
                        </article>
                    ))}
                </div>
            </div>
        </aside>
    );
}
